import React, { useEffect,useState } from 'react'
import {Link } from "react-router-dom"
import { TeacherContext } from '../../context/Teacher/teacher.context'
import {useContext} from "react"
import AdminPortalNav from '../../components/AdminPortalNav'
import { useParams,useNavigate } from 'react-router-dom'
import axios from "axios"
import Swal from "sweetalert2"

const TeacherAddQuestion = () => {
    const {name,id}=useParams()
    const [questionPostData,setQuestionPostData]=useState({
        questionOfWhichExam: id,
        question: "",
       options:[],
        answer: ""
    })
    const [option1,setOption1]=useState()
    const [option2,setOption2]=useState()
    const [option3,setOption3]=useState()
    const [option4,setOption4]=useState()
    const navigate=useNavigate()
    // const {teacherProfile,teacherToken}=useContext(TeacherContext)
    const teacherToken=JSON.parse(localStorage.getItem("teacherToken"))
   const teacherProfile=JSON.parse(localStorage.getItem("tProfile"))
    const [currentQuestionCount,setCurrentQuestionCount]=useState([])
    const [count,setCount]=useState(0)
    useEffect(()=>{
        if(!teacherToken){
            navigate("/teacher")
        }
    })

    function questionSubmitHandler(){
      
    }

    async function SaveQuestionHandler(e){
        e.preventDefault()
           try{
            const config={
                headers:{
                    Authorization:`Bearer ${teacherToken.data.token}`
                }
            }
               if(option1 && option2 && option3 && option4){
                  questionPostData.options.push(option1,option2,option3,option4)
                  
                   const questiondata=await axios.post("https://onlinetestapi.leetwolf.in/api/v1/question/add",questionPostData,config)
                   if(questiondata){
                    //   console.log(questiondata)
                    setCount(count+1)
                    setCurrentQuestionCount([...currentQuestionCount,count])
                      questionPostData.options.pop()
                      questionPostData.options.pop()
                      questionPostData.options.pop()
                      questionPostData.options.pop()
                      setQuestionPostData({
                        questionOfWhichExam: id,
                        question: "",
                       options:[],
                        answer: ""
                      })
                      document.getElementById('frm').reset()
                   }
               }
           }catch(err){
            console.log(err)
           }
    }
    function FinalSubmitHandler(){
      
        Swal.fire("You are about to leave the page")
        navigate("/teacher/addexam/success")
    }
  return (
    <div className="TestContainer">
      {teacherProfile ?<AdminPortalNav required={true} HeaderName="TEACHER" routeName="teacher" profiles={true} teacherName={teacherProfile.data.teacher.firstName}/>:<AdminPortalNav HeaderName="TEACHER" profiles={true} />}
      <div className='textpagecontentcontainer'>
           <div className='textcontainerexamandtime'>
              <h1>{name}</h1>
           </div>
           <div className='textcontaineroptionstab'>
              <div>
                 <h2>Question No. {count+1}</h2>
              </div>
              <div className='d-flex align-items-center'>
                  {/* <p className='m-1' style={{fontWeight:500}}>Time: {examContent.data.time}{" "}Min</p> */}
                  {/* <p className='m-1' style={{fontWeight:500}}>View in:</p> */}
                  {/* <p className={active==false?'m-1 languagebtnActive':'m-1 languagebtn'} onClick={languageBtnClickHandler}>English</p>
                  <p className={active==true?'m-1 languagebtnActive':'m-1 languagebtn'} onClick={languageBtnClickHandler}>Hindi</p> */}
              </div>
           </div>
           <div className='testMainContainer'>
                <div className='testMaincontainer1'>
                  {/* <h4>{active==false?questionData[currentquestion].question:"hindi"}</h4> */}
                  <form onSubmit={questionSubmitHandler} id="frm">
                         <label htmlFor="questionid" style={{fontWeight:"bold"}}>Question&nbsp;&nbsp;</label><input className='addquestioninput' id="questionid" type="text" onChange={(e)=>setQuestionPostData({...questionPostData,question:e.target.value})} required /><br />
                         <hr/>
                         <label htmlFor="option1" >Option 1&nbsp;&nbsp;</label><input className='optionsinput' id="option1" type="text" onChange={(e)=>setOption1(e.target.value)}  required/>&nbsp;&nbsp;&nbsp;
                         <label htmlFor="option2" >Option 2&nbsp;&nbsp;</label><input className='optionsinput' id="option2" type="text" onChange={(e)=>setOption2(e.target.value)}  required/><br />
                         <label htmlFor="option3" >Option 3&nbsp;&nbsp;</label><input className='optionsinput' id="option3" type="text" onChange={(e)=>setOption3(e.target.value)}  required/>&nbsp;&nbsp;&nbsp;
                         <label htmlFor="option4" >Option 4&nbsp;&nbsp;</label><input className='optionsinput' id="option4" type="text" onChange={(e)=>setOption4(e.target.value)}  required/><br />
                         <h1>Right Answer</h1>
                         <input type="text" className='optionsinput' onChange={(e)=>setQuestionPostData({...questionPostData,answer:e.target.value})} required  />
                        <button type='submit' className='saveandnextbtn' onClick={SaveQuestionHandler}>Save & Next</button>
                </form>
                </div>        
              <div className='testMaincontainer2'>
                   <h4 className='mt-2'>Total</h4>
                   <div className='d-flex flex-wrap m-1'>
                   {currentQuestionCount?currentQuestionCount.map((item,index)=><span key={index} className={index==count-1?'currentquestionnumbercontainer':'altcurrentquestionnumbercontainer'}>{index+1}</span>):''}
                   </div>
                   
                   <button className='languagebtn' onClick={FinalSubmitHandler} disabled={count<=0?true:false}>Submit</button>
                   
              </div>
           </div>
      </div>

    </div>
  )
}

export default TeacherAddQuestion