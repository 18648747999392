import React, { useContext,useEffect } from 'react'
import AdminPortalNav from '../../components/AdminPortalNav'
import { TeacherContext } from '../../context/Teacher/teacher.context'


const TeacherSelfProfile = () => {
    const {teacherProfile}=useContext(TeacherContext)
    

  return (
    <div className='profilecontainer'>
         {teacherProfile ?<AdminPortalNav  required={true} HeaderName="TEACHER" routeName="teacher" profiles={true} teacherName={teacherProfile.data.teacher.firstName}/>:<AdminPortalNav HeaderName="TEACHER" profiles={true} />}
         <div className='sprofilecontainer'>
            <div className='studentprofileminicontainer1'>
                 <h4>Teacher Details</h4>
            </div>      
            <br />
            <div className='studentprofileminicontainer2'>
                 <div className='xyz1'>
                      <label htmlFor='firstname'>FirstName</label>
                      <div className='divinputcontainer' id='firstname'>
                          <p>{teacherProfile.data.teacher.firstName}</p>
                      </div>    
                      <label htmlFor='BirthDate' className='mt-1'>BirthDate</label>
                      <div className='divinputcontainer' id='BirthDate'>
                          <p>{teacherProfile.data.teacher.birthDate}</p>
                      </div>    
                 </div>
                 <div className='xyz2'>
                      <label htmlFor='lastname'>LastName</label>
                      <div className='divinputcontainer' id='lastname'>
                          <p>{teacherProfile.data.teacher.lastName}</p>
                      </div>
                      <label htmlFor='Gender' className='mt-1'>Gender</label>
                      <div className='divinputcontainermini' id='gender'>
                          <p>{teacherProfile.data.teacher.gender}</p>
                      </div>
                 </div>
            </div>
            <div className='studentprofileminicontainer3'>
                      <label htmlFor='address'>Address</label>
                      <div className='divinputcontainer p-2' id='address'>
                          <p>{teacherProfile.data.teacher.address}</p>
                      </div>
            </div>
            <div className='studentprofileminicontainer4'>
                  <div className='xyz1'>
                      <label htmlFor='userName' className='mt-2'>UserName</label>
                      <div className='divinputcontainer' id='userName'>
                          <p>{teacherProfile.data.teacher.userName}</p>
                      </div>    
                      <label htmlFor='postal' className='mt-2'>Postal/Zip Code</label>
                      <div className='divinputcontainer' id='postal'>
                          <p>{teacherProfile.data.teacher.zipcode?teacherProfile.data.teacher.zipcode:"NO Data Found"}</p>
                      </div>    
                      <label htmlFor='email' className='mt-2'>Email</label>
                      <div className='divinputcontainer' id='email'>
                          <p>{teacherProfile.data.teacher.email}</p>
                      </div>    
                        
                  </div>
                  <div className='xyz2'>
                      {/* <label htmlFor='state' className='mt-2'>State</label>
                      <div className='divinputcontainer' id='state'>
                          <p>{studentLoginDetails.data.student.state?studentLoginDetails.data.student.state:"No Data Found"}</p>
                      </div>     */}
                      {/* <label htmlFor='school' className='mt-2'>School Name</label>
                      <div className='divinputcontainer' id='school'>
                          <p>{studentLoginDetails.data.student.schoolName}</p>
                      </div>     */}
                      <label htmlFor='mobile' className='mt-2'>Mobile Number</label>
                      <div className='divinputcontainer' id='mobile'>
                          <p>{teacherProfile.data.teacher.mobileNumber}</p>
                      </div>   
                      <label htmlFor='education' className='mt-2'>Education</label>
                      <div className='divinputcontainer' id='education'>
                          <p>{teacherProfile.data.teacher.education}</p>
                      </div>    
                      <label htmlFor='state' className='mt-2'>State</label>
                      <div className='divinputcontainer' id='state'>
                          <p>{teacherProfile.data.teacher.state}</p>
                      </div> 
                      {/* <label htmlFor='preparingfor' className='mt-2'>Preparing For</label>
                      <div className='divinputcontainer' id='preparingfor'>
                          {teacherProfile.data.teacher.preparingFor.map(item=><p>{item.label}</p>)}
                      </div>     */}
                  </div>
            </div>
       </div>
    </div>
  )
}

export default TeacherSelfProfile