import './App.css';
import {Routes,Route} from "react-router-dom"
import Home from "./pages/Home"
import RegistrationPage from './pages/RegistrationPage';
import Teacher from "./pages/Teacher"
import StudentPortal from './pages/StudentPortal';
import Wallet from './pages/Wallet';
import Adminportal from './pages/Admin/Adminportal';
import Profile from "./pages/StudentProfile/Profile"
import StudentProfileAdmin from './pages/Admin/studentprofile/StudentProfileAdmin';
import TeacherRegistration from './pages/TeacherRegistration/TeacherRegistration';
import Page1 from './pages/ForgotPassword/Page1';
import Page2 from './pages/ForgotPassword/Page2';
import Page3 from "./pages/ForgotPassword/Page3"
import TeacherDashboard from './pages/TeacherDashboard/TeacherDashboard';
import SubmitScreen from './pages/TeacherDashboard/SubmitScreen/SubmitScreen';
import TeacherProfile from './pages/Admin/TeacherProfile/TeacherProfile';
import ExamInstructionPage from "./pages/Exam/InstructionPage/ExamInstructionPage"
import PreTestScreen from './pages/Exam/PreTestScreen/PreTestScreen';
import Test from './pages/Exam/Test/Test';
import FinalScreen from './pages/Exam/FinalScreen/FinalScreen';
import TeacherSelfProfile from './pages/TeacherProfile/TeacherSelfProfile';
import TeacherAddPaper from './pages/TeacherAddPaper/TeacherAddPaper';
import TeacherAddQuestion from './pages/TeacherAddPaper/TeacherAddQuestions';
import ShowGivenExam from './pages/Exam/ShowGivenExam/ShowGivenExam';

function App() {
  return (
    <div className="App">
       <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path='/registration' element={<RegistrationPage />}></Route>
          <Route path="/teacher" element={<Teacher />}></Route>
          <Route path="/studentportal" element={<StudentPortal />}></Route>
          <Route path="/student/wallet" element={<Wallet />}></Route>
          <Route path="/admin/portal" element={<Adminportal />}></Route>
          <Route path="/student/portal/profile" element={<Profile />}></Route>
          <Route path="/admin/portal/student/:id" element={<StudentProfileAdmin />}></Route>
          <Route path="/teacher/registration" element={<TeacherRegistration/>}></Route>
          <Route path="/:role/forgotpassword/page1" element={<Page1 />}></Route>
          <Route path="/:role/forgotpassword/otpverification/page2" element={<Page2 successfull={true}/>}></Route>
          <Route path="/:role/forgotpassword/changepassword/page3" element={<Page3 />}></Route>
          <Route path="/teacher/portal" element={<TeacherDashboard />}></Route>
          <Route path="/teacher/addexam/success" element={<SubmitScreen />}></Route>
          <Route path="/admin/portal/teacher/:id" element={<TeacherProfile/>}></Route>
          <Route path="/student/:examname/:examid/starttest/instructionpage" element={<ExamInstructionPage />}></Route>
          <Route path="/student/:examname/:examid/starttest/pretestscreen" element={<PreTestScreen />}></Route>
          <Route path="/student/:examname/:examid/starttest/test" element={<Test />}></Route>
          <Route path="/student/:examname/:examid/starttest/submit/:answersArray/:totalAttempts/:Time/:minutes" element={<FinalScreen />}></Route>
          <Route path="/teacher/profile" element={<TeacherSelfProfile />}></Route>
          <Route path="/teacher/addexam/:id" element={<TeacherAddPaper />}></Route>
          <Route path="/teacher/exam/:name/:id/addquestions" element={<TeacherAddQuestion />}></Route>
          <Route path="/student/:examname/:examid/starttest/submit/review/:Time/:minutes" element={<ShowGivenExam />}></Route>
       </Routes>
    </div>
  );
}

export default App;
