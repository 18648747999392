import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import OTPInput, { ResendOTP } from "otp-input-react";
// import ShowAlert from '../../components/ShowAlert';
import {OTPContext} from "../../context/otp/otp.context"
import {useContext} from "react"
import axios from "axios"
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"

const Page2 = (props) => {
    const [OTP, setOTP] = useState("");
    const {forgotOtpPage1}=useContext(OTPContext)
    useEffect(()=>{
      console.log(forgotOtpPage1)
    },[])

    const navigate=useNavigate()
    async function VerifyOtpHandler(){
      try{
            if(OTP.length===6){
               const data=await axios.post("https://onlinetestapi.leetwolf.in/api/v1/otp/verify-otp-password",{
                data:forgotOtpPage1.data.data,
                otp:OTP
               })
               if(data){
                  navigate(`/${forgotOtpPage1.data.data.role}/forgotpassword/changepassword/page3`)
               }
            }
      }catch(
        err
      ){
        console.log(err)
        Swal.fire("Entered Otp Is Wrong")
      }
    }

  return (
    <div className='HomeContainer'>
          <div className='logincontainer'>
                    <div className='logincontentcontainer'>
                        <h3 style={{fontSize:"x-large"}}><Link to="/"><i className="fa-solid fa-circle-arrow-left mt-1 iconcolorchange" color='rgb(3,56,104)'></i></Link>&nbsp;Forgot Password</h3>
                            <div className='inputcontentcontainer'>
                                <label htmlFor='otp'>OTP</label>
                                <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false} secure />
                            </div>
                    <div className="mt-1">
                   <button className='homecontainerlsbtn' onClick={VerifyOtpHandler}>Verify</button>
                </div>  
                    </div>
    </div>
    {/* <ShowAlert msg="check your mail"/> */}
    </div>
  )
}

export default Page2


{/* <Link to="/forgotpassword/changepassword/page3"> */}