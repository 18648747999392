import React, { useContext, useEffect } from 'react';
import Navigation from '../../../components/Navbar';
import "./examinstructionpage.css"
import { Link, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/User/user.context';
import axios from "axios"


const InstructionPage = () => {
  const {examname,examid}=useParams()
  const {setExamContent}=useContext(UserContext)
  const studentLoginDetails=JSON.parse(localStorage.getItem('studentLoginDetails'))
  const studentLoginData=JSON.parse(localStorage.getItem("loginData"))
  async function LodingExamContent(){
     const config={
         headers:{
             Authorization:`Bearer ${studentLoginData?studentLoginData.data.token:''}`
         }
     }
     try{
         if(studentLoginData){
             const examcontent=await axios.get(
                 `https://onlinetestapi.leetwolf.in/api/v1/exam/${examid}` ,config 
             )
             console.log(examcontent)
             localStorage.setItem("examContent",JSON.stringify(examcontent))
             setExamContent(examcontent)
         }
     }catch(err){
         console.log(err)
     }
 }
 useEffect(()=>{
     LodingExamContent()
 },[])

  return (
    <div className='instructionpagecontainer'>
        <Navigation required={true} studentName={studentLoginDetails.data.student.firstName}/>
        <div className='instructionpagecontentcontainer'>
               <div className="instructionpageheading">
                    <h2>GENERAL INSTRUCTIONS</h2>
               </div>
               <div className='m-4 text-start '>
                    <h5 style={{fontWeight:'400',marginTop:'1.5rem'}}>
                    <span style={{fontWeight:'bold'}}>1.</span>&nbsp;&nbsp;Be in uniform(except pass out and PG students). If not, get HOD’s/class advisor’s letter of permission.
                    </h5> 
                    <h5 style={{fontWeight:'400',marginTop:'1.5rem'}}>
                    <span style={{fontWeight:'bold'}}>2.</span>&nbsp;&nbsp;Be Punctual. Late entry permitted only till the first 30 minutes. Students are expected to be seated in the examination hall by 09:30am/01:30pm. The exam hall will be closed between 09:30am/01:30pm and 09:50am/01:50pm to avoid inconvenience during question paper distribution. Late comers can enter the exam hall only by 09:50 am/01:50 pm.
                    </h5> 
                    <h5 style={{fontWeight:'400',marginTop:'1.5rem'}}>
                    <span style={{fontWeight:'bold'}}>3.</span>&nbsp;&nbsp;Wear your identity card. If not in possession, get HOD’s/class advisor’s letter of permission.
                    </h5> 
                    <h5 style={{fontWeight:'400',marginTop:'1.5rem'}}>
                    <span style={{fontWeight:'bold'}}>4.</span>&nbsp;&nbsp;The question papers will be distributed at the strike of the second bell. Invigilator will call up the question paper course code and title. The respective students must stand up and receive the question paper. Please make sure that you are given the right question paper. Please do not write anything on the question paper except your roll number.
                    </h5>  
                    <br/>
               </div>
                    <Link to={`/student/${examname}/${examid}/starttest/pretestscreen`}><button className="instructionpagenextbtn">Next</button></Link>
        </div>
    </div>
  )
}

export default InstructionPage