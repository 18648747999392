import React,{useState} from 'react'
import "./homeandregistration.css"
import { Link } from 'react-router-dom'
import Select from "react-select"
import axios from "axios"
import {useNavigate} from "react-router-dom"
import Swal from "sweetalert2"


const RegistrationPage = () => {
      const [studentDetails,setStudentDetails]=useState({
            role:"student",
            firstName:"",
            lastName:"",
            birthDate:"",
            gender:"",
            address:"",
            zipcode:"",
            schoolName:"",
            mobileNumber:"",
            preparingFor:[],
            userName:"",
            education:"",
            state:"",         
            email:"",
            password:"",
            cpassword:"",
            otp:""

      })
      const navigate=useNavigate()
      const [selectedOption, setSelectedOption] = useState(null);
      const [itemsarr,setItemsArr]=useState([])
      const [eligibleForRegistration,setEligibility]=useState(false)
      const [registrationOtp,setRegistrationOtp]=useState()
      const data=[
            {
                  value: 1,
                  label: "NDA"
                },
                {
                  value: 2,
                  label: "AFCAT"
                },
                {
                  value: 3,
                  label: "CDS"
                },
                {
                  value: 4,
                  label: "CAPF"
                }
      ]
      
      async function studentRegistrationOtpHandler(){
            try{
                  if(studentDetails.mobileNumber.length==10){
                        console.log(studentDetails)
            if(studentDetails.password==studentDetails.cpassword){
                const data=await axios.post('https://onlinetestapi.leetwolf.in/api/v1/auth/register',studentDetails)
                 console.log(data) 
                 if(data){
                  Swal.fire("Otp Send")
                  setEligibility(true)
                  //      navigate("/")
                 }
            }else{
                  console.log("password do not match")
            }
      }else{
            Swal.fire("please enter the 10 digit number")
      }
      }catch(err){
            console.log(err)
      }
      }

      async function studentRegistrationHandler(){
            try{
                     
                const data=await axios.post('https://onlinetestapi.leetwolf.in/api/v1/otp/verify-otp-register',{data:studentDetails,otp:studentDetails.otp})
                 console.log(data) 
                 if(data){
                  Swal.fire("successfully Registered")
                  setEligibility(false)
                       navigate("/")
                 }
            }catch(err){
                  console.log(err)
            }
      }
     function changeHandler(e){
         setItemsArr(e)
         console.log(itemsarr)
         setStudentDetails({...studentDetails,preparingFor:e})
     }

  return (
    <div className='HomeContainer'>
                <div className='registrationcontainer'>
                    <div className='logincontentcontainer'>
                        <h3 style={{fontSize:"x-large"}}><Link className='text-decoration-none' to="/"><i className="fa-solid fa-circle-arrow-left" style={{color:"rgb(3,56,104)"}}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Register</h3>
                            <div className='inputcontentcontainerRegistration'>
                                 <div className='inputcontentcontainerRegistrationleftside'>
                                        <label htmlFor='firstName'>FirstName</label>
                                        <input type="text" name="firstName" onChange={(e)=>setStudentDetails({...studentDetails,firstName:e.target.value})} id="firstName" />
                                        <label htmlFor='birthdate'>Birth Date</label>
                                        <input type="date" onChange={(e)=>setStudentDetails({...studentDetails,birthDate:e.target.value})} name="birthDate" id="birthdate" />
                                 </div>
                                 <div className='inputcontentcontainerRegistrationrightside'>
                                        <label htmlFor='lastname'>LastName</label>
                                        <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,lastName:e.target.value})} name="lastName" id="lastname" />
                                        <label htmlFor='gender'>Gender</label>
                                        <input type="text" name="Gender" onChange={(e)=>setStudentDetails({...studentDetails,gender:e.target.value})} id="gender" />
                                 </div>
                            </div>
                            <div className='d-flex flex-column text-start addressinput'>
                                        <label htmlFor='Address'>Address</label>
                                        <input type="text" name="Address" onChange={(e)=>setStudentDetails({...studentDetails,address:e.target.value})} id="Address" className='w-full'/>
                            </div>    
                            <div className='d-flex postalcode mt-3'>
                                  <label htmlFor='postalcode'>Postal/Zip Code:</label>
                                  <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,zipcode:e.target.value})} name="postalCode" id="postalcode" />
                                  <label htmlFor='state'>State: </label>
                                  <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,state:e.target.value})} name="state" id="state" />
                            </div>   
                            <div className='restitemscontent'>
                                  <div className='inputcontentcontainerRegistrationleftside'>
                                        <label htmlFor='email'>Email</label>
                                        <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,email:e.target.value})} name="email" id="email" />
                                        <label htmlFor='password'>Password</label>
                                        <input type="password" onChange={(e)=>setStudentDetails({...studentDetails,password:e.target.value})} name="password" id="password" />
                                        <label htmlFor='number'>Mobile Number</label>
                                        <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,mobileNumber:e.target.value})} name="mobileNumber" id="number" />
                                        <label htmlFor='preparing'>Preparing For</label>
                                        <div className='preparingforcontainer'>
                                        <Select
                                                 defaultValue={selectedOption}
                                                onChange={changeHandler}
                                                options={data}
                                                isMulti
                                                />
                                          </div>
                                          {eligibleForRegistration? 
                                        <>
                                        <label htmlFor="otp">Otp</label>
                                        <input type="text" id="otp" onChange={(e)=>setStudentDetails({...studentDetails,otp:e.target.value})} placeholder="Otp"  name="otp" />
                                        </>
                                        :''}     
                                  </div>
                                  <div className='inputcontentcontainerRegistrationrightside'>
                                        <label htmlFor='schoolname'>schoolName</label>
                                        <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,schoolName:e.target.value})} name="schoolName" id="schoolname" /> 
                                        <label htmlFor='username'>Username</label>
                                        <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,userName:e.target.value})} name="userName" id="username" />
                                        <label htmlFor='cpassword'>Confirm Password</label>
                                        <input type="password" onChange={(e)=>setStudentDetails({...studentDetails,cpassword:e.target.value})} id="cpassword" />
                                        <label htmlFor='education'>Education</label>
                                        <input type="text" onChange={(e)=>setStudentDetails({...studentDetails,education:e.target.value})} name="education" id="education" />
                                        
                                        <button className='homecontainerlsbtn mt-4' onClick={eligibleForRegistration?studentRegistrationHandler:studentRegistrationOtpHandler}>{eligibleForRegistration?'Register':'Send Otp'}</button>
                                  </div>
                            </div>     
                    <div className="mt-1">
                </div>   
             </div>
            </div>
         </div>
 
  )
}

export default RegistrationPage
