import React, { useEffect } from 'react'
import Navigation from '../components/Navbar'
import "./wallet.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useContext,useState} from "react"
import {UserContext} from "../context/User/user.context"
import {useNavigate} from "react-router-dom"
import axios from "axios"


function MyVerticallyCenteredModal(props) {
  const [amount,setAmount]=useState()
  const studentLoginDetails=JSON.parse(localStorage.getItem("studentLoginDetails"))
    function AmountHandler(e){
         setAmount(e.target.value)
    }
    async function PaymentHandler(){
      try{
        const {data:{key}}=await axios.get('https://onlinetestapi.leetwolf.in/api/razorpay/key')
        const paymentData=await axios.post("https://onlinetestapi.leetwolf.in/api/v1/pay/init-payment",{
         amount:amount*100
        })
        console.log(paymentData)
           var options = {
            key: key, // Enter the Key ID generated from the Dashboard
          amount:amount*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Test E-Buy",
            description: "This is the Test phase of Payment E-Buy",
            image: "https://randomuser.me/api/portraits/thumb/men/75.jpg",
            order_id: paymentData.data.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          callback_url: `https://onlinetestapi.leetwolf.in/api/v1/pay/paymentverification/${studentLoginDetails.data.student._id}`,
            prefill: {
                "name": "Gaurav Kumar",
                "email": "gaurav.kumar@example.com",
                "contact": "+919999999999"
            },
            notes: {
                "address": "Razorpay Corporate Office"
            },
            theme: {
                "color": "#3399cc"
            }
        };
        var rzp1 = new window.Razorpay(options);
            rzp1.open()
           
       }catch(err){
           console.log(err)
       }
        
      
    }
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
       <div className="Modalheadercontainer">
        <Modal.Header closeButton>
          <div style={{display:"flex",justifyContent:"center",fontWeight:"bold"}}>
              ADD MONEY
          </div>
        </Modal.Header>
        </div>
        <Modal.Body>
        <br/>
          <h4>Enter Money</h4>
          <input onChange={AmountHandler} style={{padding:"0.5rem"}} type="number" placeholder='input money' className='w-100' /> 
          <br />
          <br />
        </Modal.Body>
          <div className="modalfootercontainer">
        <Modal.Footer>
            <button onClick={PaymentHandler}>Pay Now</button>
        </Modal.Footer>
          </div>  
      </Modal>
     
    );
  }

const Wallet = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [money,setMoney]=useState(0)
    const studentLoginDetails=JSON.parse(localStorage.getItem("studentLoginDetails"))
    const loginData=JSON.parse(localStorage.getItem("loginData"))
    const navigate=useNavigate()
    useEffect(()=>{
       if(!studentLoginDetails){
           navigate("/")
       }
    },[])

    async function loadBalance(){
      try{
           const balanceData=await axios.get("https://onlinetestapi.leetwolf.in/api/v1/student/details",{
            headers:{
              Authorization:`Bearer ${loginData?loginData.data.token:''}`
            }
           })
           if(balanceData){
              setMoney(balanceData.data.student.WalletBalance)
           }
      }catch(err){
        console.log(err)
      }
    }

    useEffect(()=>{
      loadBalance()
    },[])  
  
  return (
    <div>
       <Navigation required={true} studentName={studentLoginDetails.data.student.firstName}/>
       <div className='walletbalancestrip'>
             <h2>WALLET</h2>     
             <span onClick={() => setModalShow(true)}>Add Money</span>
       </div>
       <div className="d-flex text-start m-4" style={{fontSize:"large",fontWeight:"bold"}}>
        <span>Balance:{money}</span>
       </div>
       <div className="modalmaincontainer">
       <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
       </div>
    </div>
  )
}

export default Wallet