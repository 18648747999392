import React, { useEffect } from 'react'
import AdminPortalNav from '../../components/AdminPortalNav'
import { useContext,useState } from 'react'
import { TeacherContext } from '../../context/Teacher/teacher.context'
import "./teacheraddpaper.css"
import axios from "axios"
import {useNavigate} from "react-router-dom"

const TeacherAddPaper = () => {
   const teacherToken=JSON.parse(localStorage.getItem("teacherToken"))
   const teacherProfile=JSON.parse(localStorage.getItem("tProfile"))
   const [examData,setExamData]=useState({
      name:"",
      time:0,
      questions:0,
      credit:0,
      examCategory:""
   })
   const navigate=useNavigate()
   useEffect(()=>{
      if(!teacherToken){
         navigate("/teacher")
      }
   })

   async function ExamSubmitHandler(e){
      e.preventDefault()
      try{
         const config={
            headers:{
                Authorization:`Bearer ${teacherToken.data.token}`
            }
        }
           const examdata=await axios.post('https://onlinetestapi.leetwolf.in/api/v1/exam',examData,config)
           if(examdata){
            navigate(`/teacher/exam/${examdata.data.exam.name.toLowerCase()}/${examdata.data.exam._id}/addquestions`)
           }
      }catch(err){
         console.log(err)
      }
   }
  return (
    <div className='TeacherMainContainer'>
    {teacherProfile ?<AdminPortalNav required={true} HeaderName="TEACHER" routeName="teacher" profiles={true} teacherName={teacherProfile.data.teacher.firstName}/>:<AdminPortalNav HeaderName="TEACHER" profiles={true} />}
      <form onSubmit={ExamSubmitHandler} className="examformHandler">
         <label htmlFor='examname'>Exam Name</label>
         <input type="text" onChange={(e)=>setExamData({...examData,name:e.target.value})}  id="examname"/>
         <label htmlFor='examtime'>Exam Time</label>
         <input type="number" onChange={(e)=>setExamData({...examData,time:e.target.value})}  id="examtime"/>
         <label htmlFor='examtotalques'>Total Number of Questions</label>
         <input type="number" onChange={(e)=>setExamData({...examData,questions:e.target.value})}  id="examtotalques"/>
         <label htmlFor='examcredit'>Exam Credit</label>
         <input type="number" onChange={(e)=>setExamData({...examData,credit:e.target.value})}  id="examcredit"/>
         <label htmlFor='examcredit'>Exam Category</label>
         <input type="text" onChange={(e)=>setExamData({...examData,examCategory:(e.target.value).toUpperCase()})}  id="examcredit"/>
         <button >Submit</button>
      </form>
    </div>
  )
}

export default TeacherAddPaper