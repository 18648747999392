import React, { useEffect } from 'react'
import { Table } from 'react-bootstrap'
import AdminPortalNav from '../../components/AdminPortalNav'
import "./teacherdashboard.css"
import { useState } from 'react'
// import teacherpaperdata from "../../data/teacherpaperdata.json"
import { Link,useNavigate } from 'react-router-dom'
import { TeacherContext } from '../../context/Teacher/teacher.context'
import { useContext } from 'react'
import axios from 'axios'


const TeacherDashboard = () => {
    const [studentSearchTerm,setStudentSearchTerm]=useState('')
    const [datesearchstudent,setDateSearchStudent]=useState('')
    const [teacherpaperdata,setTeacherpaperdata]=useState()
    const [tProfile,setTProfile]=useState()
    const navigate=useNavigate()
    
    const {setTeacherProfile}=useContext(TeacherContext)
    var teachersavedToken
    const teacherToken=JSON.parse(localStorage.getItem("teacherToken"))
    teachersavedToken=teacherToken
    useEffect(()=>{
       if(!localStorage.getItem("teacherToken")){
           navigate("/teacher")
       }
    },[])
    async function TeacherDetails(){
        try{
            const config={
                headers:{
                    Authorization:`Bearer ${teachersavedToken?teachersavedToken.data.token:teacherToken.data.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }
            if(teacherToken && config){
                  const profiledata=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/teacher/details',config)
                  if(profiledata){
                      localStorage.setItem("tProfile",JSON.stringify(profiledata))
                      setTeacherProfile(profiledata)
                      setTProfile(profiledata)
                }
            }
        }catch(err){
            console.log(err)
        }
    }
    async function GetAllTeacherExams(){
        try{
            const config={
                headers:{
                    Authorization:`Bearer ${teachersavedToken?teachersavedToken.data.token:teacherToken.data.token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }
            const teacherexamdata=await axios.get("https://onlinetestapi.leetwolf.in/api/v1/exam",config)
            if(teacherexamdata){
                setTeacherpaperdata(teacherexamdata.data)
                console.log(teacherexamdata.data)
            }
        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        TeacherDetails()
    },[])
    
    useEffect(()=>{
        GetAllTeacherExams()
    },[])
    function filterNameHandler(val){
        if(studentSearchTerm==''){
              return val
        }else if(val.name.toLowerCase().includes(studentSearchTerm)){
         return val
        }
   }
   function filterCategoryHandler(val){
    if(datesearchstudent==''){
        return val
  }else if(val.examCategory.toLowerCase().includes(datesearchstudent)){
   return val
  }
  }
  return (
    <div className='TeacherMainContainer'>
       {tProfile?<AdminPortalNav HeaderName="TEACHER" routeName="teacher" profiles={true} teacherName={tProfile.data.teacher.firstName}/>:<AdminPortalNav HeaderName="TEACHER" profiles={true} />}
       <div className='teacherdashboardcontentcontainer'>
           <div className='teacheraddpapercontainer'>
                <Link to={`/teacher/addExam/${tProfile?tProfile.data.teacher._id:''}`}> <button >Add Paper</button></Link>
           </div>
           <div className='teacherpapercontainer'>
                  <h3>Papers</h3>
           </div>
           <div className='searchcontainer'>
               <input type="text" onChange={(e)=>setStudentSearchTerm(e.target.value)} placeholder="By Name" id="searchbynameforstudent"/>
               <label htmlFor='searchbynameforstudent'>&nbsp;<i className='fa-solid fa-search'></i></label>
               <input type="text" onChange={(e)=>setDateSearchStudent(e.target.value)} placeholder="By Category" id="searchbydateforstudent"/>
               <label htmlFor='searchbydateforstudent'>&nbsp;<i className='fa-solid fa-search'></i></label>
           </div>
           
           <Table>
               <thead>
                  <tr>
                  <th>S.No</th>
                      <th>Exam Name</th>
                      <th>Questions</th>
                      <th>Category</th>
                      <th>Details</th>
                  </tr>
               </thead>
               <tbody>
                   {teacherpaperdata && teacherToken?
                    teacherpaperdata.exams.filter(val=>filterNameHandler(val)).filter(val=>filterCategoryHandler(val)).map((item,index)=>
                     <tr>
                        <td>{index+1}</td>
                        <td>{item.name}</td>
                        <td>{item.questions}</td>
                        <td>{item.examCategory}</td>
                        <td><span className='studentdetailsbtn'>Details</span></td>
                     </tr>
                    ):''
                   }
               </tbody>
           </Table>
       </div>
    </div>
  )
}

export default TeacherDashboard