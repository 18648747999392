import React from 'react'
import { Link } from 'react-router-dom'
import AdminPortalNav from '../../../components/AdminPortalNav'
import "./submitscreen.css"

const SubmitScreen = () => {
  return (
    <div>
       <AdminPortalNav required={true} HeaderName="TEACHER" routeName="teacher"/>
       <div className='submitscreenmaincontainer'>
       <i class="fa-solid fa-circle-check fa-2xl"></i>
       <h2>SUBMISSION SUCCESSFUL</h2>
       <Link to="/teacher/portal"><button>Home</button></Link>
       </div>
    </div>
  )
}

export default SubmitScreen