import React from "react"
import {createContext,useState} from "react"

export const UserContext=createContext()

export const UserProvider=({children})=>{
    const [studentLoginData,setStudentLoginData]=useState()
    const [studentLoginDetails,setStudentLoginDetails]=useState()
    const [examContent,setExamContent]=useState()

    return(
        <UserContext.Provider
          value={{
            studentLoginData,
            setStudentLoginData,
            setStudentLoginDetails,
            studentLoginDetails,
            setExamContent,
            examContent
          }}
          >
            {children}
          </UserContext.Provider>
    )
}


