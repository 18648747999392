import { createContext,
         useState 
         } from "react";

export const TeacherContext=createContext()

export const TeacherProvider=({children})=>{
    const [teacherToken,setTeacherToken]=useState()
    const [teacherProfile,setTeacherProfile]=useState()
     return(
        <TeacherContext.Provider
           value={{
            setTeacherToken,
            teacherToken,
            setTeacherProfile,
            teacherProfile
           }
           }
        >
            {children}
        </TeacherContext.Provider>   
     )

}