import {createContext} from "react"
import {useState} from "react"

export const OTPContext=createContext()

export const OTPProvider=({children})=>{
    const [forgotOtpPage1,setForgotOtpPage1]=useState()
    return (
        <OTPContext.Provider
            value={{
                setForgotOtpPage1,
                forgotOtpPage1
            }}
        >
            {children}
        </OTPContext.Provider>
    )
}