import React,{useContext, useEffect, useState} from 'react'
import Navigation from '../../../components/Navbar'
import "./pretestscreen.css"
import { useParams,Link } from 'react-router-dom'
import { UserContext } from '../../../context/User/user.context'

const PreTestScreen = () => {
  const [checked,setChecked]=useState(false)
    let examname=useParams()
      examname.examname=examname.examname[0].toUpperCase()+examname.examname.slice(1)
      let {examid}=useParams()
      console.log(examname,examid)
      function changeHandler(e){
            console.log(e.target.checked)
             setChecked(e.target.checked)
      } 
      const examContent=JSON.parse(localStorage.getItem("examContent"))
      const studentLoginDetails=JSON.parse(localStorage.getItem("studentLoginDetails"))
      useEffect(()=>{
         console.log(examContent)
      },[])
  return (
    <div>
       <Navigation required={true} studentName={studentLoginDetails.data.student.firstName}/>
       <div className='pretestscreencontainer'>
            <div className='pretestscreenheading'>
                <h2>{examname.examname}</h2>
            </div>
            <div className='pretestscreenduration'>
                 <h4>Duration:&nbsp;{examContent?examContent.data.time:''}{" "}Min</h4>
                 <h4>Maximum Marks:&nbsp;{examContent?examContent.data.credit:''}</h4>
            </div>
            <ol>
                <h4>Read The Following Instructions Carefully:</h4>
               <li>This Test having 10 questions total</li>
               <li>Each question will have 10 questions out of which only one is correct</li>
               <li>you have to finish the test in 9 min</li>
               <li>Try not to guess the answer as there is negative marking </li>
            </ol>
            <div className="text-start m-4">
              <h4>
                Declaration
              </h4>
              <input type="checkbox" onChange={changeHandler} />I have read all the instructions and ready to take the exam
            </div>
            <Link to={`/student/${examname.examname.toLowerCase()}/${examid}/starttest/test`}><button className={checked?'preteststartbtn':'preteststartbtndisabled'} disabled={checked?false:true} onClick={()=>console.log("clicked")}>
                  I Am Ready To Begin
            </button></Link>
       </div>
    </div>
  )
}

export default PreTestScreen