import React from 'react'
import { Table } from 'react-bootstrap'
import Navigation from '../components/Navbar'
import "./studentportal.css"
import myexam from "../data/myexamslist.json"
import {useState} from "react";
import Modal from 'react-bootstrap/Modal';
import { Link,useNavigate } from 'react-router-dom'
import {useEffect} from 'react'
import {useContext} from 'react'
import {UserContext} from "../context/User/user.context"
import axios from "axios"
import Swal from "sweetalert2"

function MyVerticallyCenteredModal(props) {
  const studentLoginData=JSON.parse(localStorage.getItem("loginData"))
  const config={
    headers:{
          Authorization:`Bearer ${studentLoginData.data.token}`
        }
      }
    async function PurchaseExamHandler(){
      try{
        const data=await axios.post('https://onlinetestapi.leetwolf.in/api/v1/student/buy-exam',{
          _id:props.item._id
        },config)
        if(data){
          console.log(data)
          Swal.fire("Purchased SuccessFully Added to the cart please Signin again to check")
        }
      }catch(err){
        console.log(err)
      }
      console.log(props.item._id)
    }
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
       <div className="Modalheadercontainer">
        <Modal.Header closeButton>
          <div style={{display:"flex",justifyContent:"center",fontWeight:"bold"}}>
              Confirm
          </div>
        </Modal.Header>
        </div>
        <Modal.Body>
        <br/>
          <div className='d-flex flex-column'>
              <h4>Do You Want To Confirm?</h4>
              <div className='modalfootercontainer mt-3'>
                 {
                  props.starttest?
                  <>
                 <Link to={`/student/${(props.examname)}/${props.examid}/starttest/instructionpage`}>
                 <button className="">Confirm</button>
                 </Link>
                 </>:
                 <button className="" onClick={PurchaseExamHandler}>Confirm</button>
                 
                 }
                 <button className='modalcontainerbtn'>Cancel</button>
              </div>   
          </div> 
          <br />
          <br />
        </Modal.Body>
      </Modal>
     
    );
  }

const StudentPortal = () => {

  const [active,setActive]=useState('')
  const [modalShow, setModalShow] = React.useState(false);
  const [modalActiveStartTest,setModalActiveStartTest]=React.useState(false)
  const [paperName,setPaperName]=React.useState('')
  const [examid,setExamId]=React.useState('')
  const [studentLoginDetails,setLoginDetails]=useState()
  const [item,setItem]=useState()
  const [allExamData,setAllExamData]=useState()
  const [nda,setNda]=useState()
  const [cds,setCds]=useState()
  const [afcat,setAfcat]=useState()
  const [capf,setCapf]=useState()
 
  const {setStudentLoginDetails}=useContext(UserContext)
  var studentLoginDatareload
  const studentLoginData=JSON.parse(localStorage.getItem("loginData"))
  studentLoginDatareload=studentLoginData
  const navigate=useNavigate()
  useEffect(()=>{
      var x=JSON.parse(localStorage.getItem("loginData"))
      if(!x){
          navigate("/")
      }
  },[])

  const config={
    headers:{
          Authorization:`Bearer ${studentLoginDatareload?studentLoginDatareload.data.token:studentLoginData.data.token}`,
          "Access-Control-Allow-Origin": "*",
        }
      }

      async function EmptyGivenExam(){
        try{
            const data=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/student/emptygivenexam',config)
            if(data){
              console.log(" data is Empty")
            }else{
              console.log("Something went wrong ")
            }
        }catch(err){
          console.log(err)
        }
      }
      useEffect(()=>{
        EmptyGivenExam()
      },[])
  
      async function getEveryExams(){
        if(studentLoginData){
          const data1=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/exam/nda/nda',config)
          const data2=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/exam/cds/cds',config)
          const data3=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/exam/afcat/afcat',config)
          const data4=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/exam/capf/capf',config)
          if(data1){
             setNda(data1)
        
          }
          if(data2){
            setCds(data2)

         }
         if(data3){
          setAfcat(data3)
      
       }
       if(data4){
        setCapf(data4)
 
     }
    }
      }
      useEffect(()=>{
        getEveryExams()
      },[])
    var studentlocal=''
    async function getStudentDetails(){
      try{
        if(studentLoginData && config){
          const LoggedInStudentDetails=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/student/details',config)
          if(LoggedInStudentDetails){
            setStudentLoginDetails(LoggedInStudentDetails)
            studentlocal=LoggedInStudentDetails
            localStorage.setItem("studentLoginDetails",JSON.stringify(LoggedInStudentDetails))
            setLoginDetails(LoggedInStudentDetails)
          }
        }
      }catch(err){
        console.log(err)
      }
    }

    // async function getAllExams(){
    //   try{
    //       const allExams=await axios.get("https://onlinetestapi.leetwolf.in/api/v1/exam",config)
    //       // if(allExams){
    //       //   console.log(allExams)
    //       // }
    //   }catch(err){
    //     console.log(err)
    //   }
    // }
      useEffect(()=>{
          getStudentDetails()
          // getAllExams()
      },[])
  

  function BuyClickHandler(item){
    setModalShow(true)
    setModalActiveStartTest(false)
    setItem(item)
  }
  function StartTestClickHandler(item){
    setModalShow(true)
    setModalActiveStartTest(true)
    setPaperName(item.name)
    setExamId(item._id)
  }
  return (
    <div className='studentportalmaincontainer'>
       {studentLoginDetails?<Navigation studentName={studentlocal?studentlocal.data.student.firstName:studentLoginDetails.data.student.firstName}/>:<Navigation/>}
       <div className='examcontainer'>
           <h3>Exam</h3>
           <ul>
               <li onClick={()=>setActive('AFCAT')}>AFCAT</li>
               <li onClick={()=>setActive('NDA')}>NDA</li>
               <li onClick={()=>setActive('CDS')}>CDS</li>
               <li onClick={()=>setActive('CAPF')}>CAPF</li>
           </ul>
       </div>
       <div className='blankcontainer'>
                    
       </div>
       <div className='myexamstrip'>
            <h4 className='styleh4tag' onClick={()=>setActive('')}>MyExam</h4>
       </div>
       <div className='myexamlist'>
       <Table striped>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Exam Name</th>
          {active==''?'':<th>Credit</th>}
          <th>Number of Questions</th>
          <th>Duration</th>
          <th>Process</th>
        </tr>
      </thead>

      {active==='NDA'?
        <>
        {nda?
        nda.data.map((item,index)=>
        <tbody key={item._id}>
            <tr>
                <td>{index+1}</td>
                <td>{item.name}</td>
                <td>{item.credit}</td>
                <td>{item.questions}</td>
                <td>{item.time}</td>
                <td><span className='myexamtablelastchild' onClick={()=>BuyClickHandler(item)}>Buy</span></td>
            </tr>
        </tbody>
        ):''}
        </>
      :active==="CDS"?
      <>
      {cds?
        cds.data.map((item,index)=>
        <tbody key={item._id}>
            <tr>
                <td>{index+1}</td>
                <td>{item.name}</td>
                <td>{item.credit}</td>
                <td>{item.questions}</td>
                <td>{item.time}</td>
                <td><span  className='myexamtablelastchild' onClick={()=>BuyClickHandler(item)}>Buy</span></td>
            </tr>
        </tbody>):''}</>:active==="AFCAT"?
        <>
        {afcat?
        afcat.data.map((item,index)=>
        <tbody key={item._id}>
            <tr>
                <td>{index+1}</td>
                <td>{item.name}</td>
                <td>{item.credit}</td>
                <td>{item.questions}</td>
                <td>{item.time}</td>
                <td><span  className='myexamtablelastchild' onClick={()=>BuyClickHandler(item)}>Buy</span></td>
            </tr>
        </tbody>):''}</>:active==="CAPF"?
        <>
        {capf?
        capf.data.map((item,index)=>
        <tbody key={item._id}>
            <tr>
                <td>{index+1}</td>
                <td>{item.name}</td>
                <td>{item.credit}</td>
                <td>{item.questions}</td>
                <td>{item.time}</td>
                <td><span  className='myexamtablelastchild' onClick={()=>BuyClickHandler(item)}>Buy</span></td>
            </tr>
        </tbody>):''}</>:active==='' && studentlocal!==''?
        <>
        {studentlocal?
        studentlocal.data.student.myExams.map((item,index)=>
          <tbody key={item._id}>
            <tr>
                <td>{index+1}</td>
                <td>{item.name}</td>
                {/* <td>{item.credit}</td> */}
                <td>{item.questions}</td>
                <td>{item.time}</td>
                <td><span className='myexamtablelastchild' onClick={()=>StartTestClickHandler(item)}>Start Test</span></td>
            </tr>
        </tbody>):''}</>:active==='' ?
        <>
        {studentLoginDetails?
        studentLoginDetails.data.student.myExams.map((item,index)=>
          <tbody key={item._id}>
            <tr>
                <td>{index+1}</td>
                <td>{item.name}</td>
                {/* <td>{item.credit}</td> */}
                <td>{item.questions}</td>
                <td>{item.time}</td>
                <td><span className='myexamtablelastchild' onClick={()=>StartTestClickHandler(item)}>Start Test</span></td>
            </tr>
        </tbody>):''}</>:''}

    </Table>
       </div>
       <div className="modalmaincontainer">
       {modalActiveStartTest && paperName!==''?
       <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        starttest={true}
        examname={paperName}
        examid={examid}
      />:item!==null?
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        item={item}
      />:<MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
       }
       </div>
    </div>
  )
}

export default StudentPortal