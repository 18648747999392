import React, { useEffect } from 'react'
import AdminPortalNav from '../../components/AdminPortalNav'
import "./admin.css"
import { useState,useContext } from 'react'
// import studentdataforadmin from "../../data/studentdataforadmin.json"
// import teachersdataadmin from "../../data/teachersdataadmin.json"
import { Table } from 'react-bootstrap'
import {Link,useNavigate} from "react-router-dom"
import {AdminContext} from "../../context/Admin/Admin.context"
import axios from "axios"

const Adminportal = () => {
      const [active,setActive]=useState()
      const [studentSearchTerm,setStudentSearchTerm]=useState('')
      const [teacherSearchTerm,setTeacherSearchTerm]=useState('')
      const [datesearchstudent,setDateSearchStudent]=useState('')
      const [datesearchteacher,setDateSearchTeacher]=useState('')
      const {adminLoginDetailsContext,studentdataforadmin,teachersdataadmin}=useContext(AdminContext)
      const navigate=useNavigate()
      useEffect(()=>{
        var x=localStorage.getItem("admintoken")
        if(!x){
            navigate("/")
        }
      })
      useEffect(()=>{
        //    studentProfileDetails()
        console.log(studentdataforadmin)
      },[])
      function studentbtnHandler(){
            setActive("student")
      }
      function teacherbtnHandler(){
           setActive("teacher")
      }
      function filterNameHandler(val){
           if(studentSearchTerm==''){
                 return val
           }else if(val.name.toLowerCase().includes(studentSearchTerm)){
            return val
           }
      }
      function filterTeacherHandler(val){
        if(teacherSearchTerm==''){
            return val
      }else if(val.name.toLowerCase().includes(teacherSearchTerm)){
       return val
      }
      }
      function filterDateHandler(val){
        if(datesearchstudent==''){
            return val
      }else if(val.createdAt.toLowerCase().includes(datesearchstudent)){
       return val
      }
      }
      function filterDateTeacherHandler(val){
        if(datesearchteacher==''){
            return val
      }else if(val.createdAt.toLowerCase().includes(datesearchteacher)){
       return val
      }
      }
  return (
    <div className='adminmainportalcontainer'>
      <AdminPortalNav HeaderName="ADMIN"/>
      
      <div className='selectcontainer'>
           <span className={active==="student"?'selectionbtnonclick':'selectionbtn'} onClick={studentbtnHandler}>Student</span>
           <span className={active==="teacher"?'selectionbtnonclick':'selectionbtn'} onClick={teacherbtnHandler} >Teacher</span>
      </div>
      {active==="student"?
       <div>
           <div className='infoadmin'>
              <h2>Students</h2>
           </div>
           <div className='searchcontainer'>
               <input type="text" onChange={(e)=>setStudentSearchTerm(e.target.value)} placeholder="By Name" id="searchbynameforstudent"/>
               <label htmlFor='searchbynameforstudent'>&nbsp;<i className='fa-solid fa-search'></i></label>
               <input type="text" onChange={(e)=>setDateSearchStudent(e.target.value)} placeholder="By Date" id="searchbydateforstudent"/>
               <label htmlFor='searchbydateforstudent'>&nbsp;<i className='fa-solid fa-search'></i></label>
           </div>
           
           
           <Table>
               <thead>
                  <tr>
                  <th>S.No</th>
                      <th>Student Name</th>
                      <th>Registration Date</th>
                      <th>Status</th>
                      <th>Details</th>
                  </tr>
               </thead>
               <tbody>
                   {
                    adminLoginDetailsContext && studentdataforadmin?
                    studentdataforadmin.filter(val=>filterNameHandler(val)).filter(val=>filterDateHandler(val)).map((item,index)=>
                     <tr>
                        <td>{index+1}</td>
                        <td>{item.name}</td>
                        <td>{item.createdAt}</td>
                        <td>{(item.isActive).toString()}</td>
                        <td><Link to={`/admin/portal/student/${item._id}`} className='text-decoration-none'><span className='studentdetailsbtn'>Details</span></Link></td>
                     </tr>
                    ) 
                   :null}
               </tbody>
           </Table>

       </div>:
       <div>
           <div className='infoadmin'>
              <h2>Teachers</h2>
           </div>
           <div className='searchcontainer'>
               <input type="text" onChange={(e)=>setTeacherSearchTerm(e.target.value)} placeholder="By Name" id="searchforteacherbyname"/>
               <label htmlFor='searchforteacherbyname'>&nbsp;<i className='fa-solid fa-search'></i></label>
               <input type="text" onChange={(e)=>setDateSearchTeacher(e.target.value)} placeholder="By Date" id="searchbydateforteacher"/>
               <label htmlFor='searchbydateforteahcer'>&nbsp;<i className='fa-solid fa-search'></i></label>
           </div>
           <Table>
               <thead>
                  <tr>
                      <th>S.No</th>
                      <th>Teacher Name</th>
                      <th>Registration Date</th>
                      <th>Status</th>
                      <th>Details</th>
                  </tr>
               </thead>
               <tbody>
                   {
                    adminLoginDetailsContext && teachersdataadmin?
                    teachersdataadmin.filter(val=>filterTeacherHandler(val)).filter(val=>filterDateTeacherHandler(val)).map((item,index)=>
                     <tr>
                        <td>{index+1}</td>
                        <td>{item.name}</td>
                        <td>{item.createdAt}</td>
                        <td>{(item.isActive).toString()}</td>
                        <td><Link to={`/admin/portal/teacher/${item._id}`} className="text-decoration-none"><span className='studentdetailsbtn'>Details</span></Link></td>
                     </tr>
                    )
                    :null}
               </tbody>
           </Table>
       </div> 
      }
    </div>
  )
}

export default Adminportal