import React from 'react'
import "./adminnav.css"
import {Link} from "react-router-dom"

import Spinner from 'react-bootstrap/Spinner';
import  {useContext} from "react"
import {AdminContext} from "../context/Admin/Admin.context"
import {useNavigate} from "react-router-dom"
import {TeacherContext} from "../context/Teacher/teacher.context"
import Swal from "sweetalert2"

function BasicExample() {
  return (
    <Spinner animation="border" size="sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

const AdminPortalNav = (props) => {
  const {setAdminLoginDetailsContext}=useContext(AdminContext)
  const {setTeacherToken}=useContext(TeacherContext)
  const navigate=useNavigate()
  async function AdminLogoutHandler(){
    try{ 
      setAdminLoginDetailsContext(null)
      localStorage.removeItem("admintoken")
      navigate("/")
    }catch(err){
         console.log(err)
         Swal.fire("Something went wrong!")
    }
  }
  async function TeacherLogoutHandler(){
    try{
      setTeacherToken(null)
      localStorage.removeItem("tProfile")
      localStorage.removeItem("teacherToken")
      navigate("/teacher")
    }catch(err){
        console.log(err)
        Swal.fire("Something Went Wrong!")
    }
  }
  return (
    <div className="NavbarCustom">
        {props.required?<h4><Link to={`/${props.routeName}/portal`} className='gobackcolor'><i className="fa-solid fa-circle-arrow-left"></i></Link>&nbsp;&nbsp;{props.HeaderName} DASHBOARD</h4>:<h4>{props.HeaderName} DASHBOARD</h4>}
        <ul>
            <li onClick={props.HeaderName=="ADMIN"?AdminLogoutHandler:TeacherLogoutHandler}>Logout</li>
            {props.profiles?<li>{props.teacherName?<Link style={{"textDecoration":"none","color":"black"}} to={`/teacher/profile`}>{props.teacherName}</Link>:<BasicExample />}</li>:''}
        </ul>
    </div>
  )
}

export default AdminPortalNav