import React, { useState,useEffect, useContext } from 'react'
import { json, useParams } from 'react-router-dom'
import Navigation from '../../../components/Navbar'
import "./test.css"
import questionData from "../../../data/questionsdata.json"
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from '../../../context/User/user.context'
import { useNavigate } from 'react-router-dom'
import axios from "axios"
import Swal from "sweetalert2"

const Test = () => {
    const {examname,examid}=useParams()
    const [currentquestion,setCurrentQuestion]=useState(0)
    const [answersArray,setAnswersArray]=useState([])
    const [totalAttempts,setTotalAttempts]=useState([])
    const [myCurrentAnswer,setMyCurrentAnswer]=useState([])
    const [active,setActive]=useState(false)
    const [Time,setTime]=useState()
    const [minutes,setMinutes]=useState()
    const [timeup,setTimeUp]=useState(false)
    const [apiQuestionData,setApiQuestionData]=useState()
    const navigate=useNavigate()
    
    // const {studentLoginDetails,studentLoginData,examContent}=useContext(UserContext)
    const studentLoginDetails=JSON.parse(localStorage.getItem("studentLoginDetails"))
    const studentLoginData=JSON.parse(localStorage.getItem("loginData"))
    const examContent=JSON.parse(localStorage.getItem('examContent'))
    useEffect(()=>{
        if(!studentLoginData){
            navigate("/")
        }
    })
    useEffect(()=>{
       console.log(examContent)
    },[])
    var time=examContent.data.time*60
    var name=examname[0].toUpperCase()+examname.slice(1)
    useEffect(()=>{
        
    },[])

    async function GetAllQuestions(){
        const config={
            headers:{
                Authorization:`Bearer ${studentLoginData.data.token}`
            }
        }
        try{
            console.log(examid)
            const questionData=await axios.get(`https://onlinetestapi.leetwolf.in/api/v1/question/${examid}`,config)
            if(questionData){
                setApiQuestionData(questionData.data.questions)
                console.log(questionData)
            }
        }catch(err){
            console.log(err)
        }
    }
    useEffect(()=>{
        GetAllQuestions()
    },[])
    const notify=()=>{
        toast.warn('Time Up Submit To see Result', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    }
    function updateCountDown(){
        var minutes=Math.floor(time/60)
        var seconds=time%60
        setMinutes(minutes)
        setTime(seconds)
        if(time>0){
            time--
        }else{
             setTimeUp(true)
        }
    }
    useEffect(()=>{
        if(timeup==true){

            notify()
        }
    },[timeup])
    
    useEffect(()=>{
        setInterval(updateCountDown,2000)
   
    },[])    
    
    var AllQuestionHandler=[]
    async function questionSubmitHandler(e){
        e.preventDefault()
        console.log(currentquestion)
        if(currentquestion+1<apiQuestionData.length && myCurrentAnswer){
            setCurrentQuestion(()=>currentquestion+1)
        }else{
            setCurrentQuestion(currentquestion)
        }
        if(myCurrentAnswer==apiQuestionData[currentquestion].answer){
            console.log(myCurrentAnswer)
            setAnswersArray([...answersArray,myCurrentAnswer])
        }
        // AllQuestionHandler([...AllQuestionHandler,{question:apiQuestionData[currentquestion],
        //    result:myCurrentAnswer==apiQuestionData[currentquestion].answer?'right':'wrong',
        //    answer:myCurrentAnswer
        // }])
        const postdata=await axios.post('https://onlinetestapi.leetwolf.in/api/v1/student/show-exam',{
            question:apiQuestionData[currentquestion],
            result:myCurrentAnswer==apiQuestionData[currentquestion].answer?'right':'wrong',
           answer:myCurrentAnswer
        },{
            headers:{
                Authorization:`Bearer ${studentLoginData.data.token}`
            }
        })
        if(postdata){
            console.log(postdata)
        }
        console.log(AllQuestionHandler)
        // localStorage.setItem("GivenExam",JSON.stringify(AllQuestionHandler))
        if(myCurrentAnswer){
            setTotalAttempts([...totalAttempts,myCurrentAnswer])
        }else{      
            alert("please select atleast one answer")    
        }
    }

    function languageBtnClickHandler(){
        if(active==false){
            setActive(true)
        }else{
            setActive(false)
        }
    }

    function skipclickhandler(){
        if(currentquestion+1<apiQuestionData.length){
            setCurrentQuestion(()=>currentquestion+1)
        }else{
            setCurrentQuestion(currentquestion)
        }
    }

  return (
    <div className="TestContainer">
      <Navigation required={true} studentName={studentLoginDetails.data.student.firstName}/>
      <div className='textpagecontentcontainer'>
           <div className='textcontainerexamandtime'>
              <h3>{name}</h3><p>Time&nbsp;&nbsp;{minutes}&nbsp;:&nbsp;&nbsp;{Time}</p>
           </div>
           <div className='textcontaineroptionstab'>
              <div>
                 <h2>Question No. {currentquestion+1}</h2>
              </div>
              <div className='d-flex align-items-center'>
                  <p className='m-1' style={{fontWeight:500}}>Time: {examContent.data.time}{" "}Min</p>
                  <p className='m-1' style={{fontWeight:500}}>View in:</p>
                  <p className={active==false?'m-1 languagebtnActive':'m-1 languagebtn'} onClick={languageBtnClickHandler}>English</p>
                  <p className={active==true?'m-1 languagebtnActive':'m-1 languagebtn'} onClick={languageBtnClickHandler}>Hindi</p>
              </div>
           </div>
           <div className='testMainContainer'>
                <div className='testMaincontainer1'>
                  <h4>{active==false && apiQuestionData?apiQuestionData[currentquestion].question:"hindi"}</h4>
                  <form onSubmit={questionSubmitHandler}>
                        <input className='optionsinputchoice' type="radio" id="option1" name="options" 
                           value={apiQuestionData?apiQuestionData[currentquestion].options[0]:''} 
                           onChange={(e)=>setMyCurrentAnswer(e.target.value)}
                        />
                        <label htmlFor="option1" style={{fontWeight:'500',fontSize:'18px',marginLeft:'0.5rem'}}>{apiQuestionData?apiQuestionData[currentquestion].options[0]:''}</label><br/>
                        <input className='optionsinputchoice' type="radio" id="option2" name="options" 
                           value={apiQuestionData?apiQuestionData[currentquestion].options[1]:''} 
                           onChange={(e)=>setMyCurrentAnswer(e.target.value)}
                        />
                        <label htmlFor="option2" style={{fontWeight:'500',fontSize:'18px',marginLeft:'0.5rem'}}>{apiQuestionData?apiQuestionData[currentquestion].options[1]:''}</label><br/>
                        <input className='optionsinputchoice' type="radio" id="option3" name="options" 
                              value={apiQuestionData?apiQuestionData[currentquestion].options[2]:''} 
                              onChange={(e)=>setMyCurrentAnswer(e.target.value)}
                        />
                        <label htmlFor="option3" style={{fontWeight:'500',fontSize:'18px',marginLeft:'0.5rem'}}>{apiQuestionData?apiQuestionData[currentquestion].options[2]:''}</label><br/>
                        <input className='optionsinputchoice' type="radio" id="option4" name="options" 
                             value={apiQuestionData?apiQuestionData[currentquestion].options[3]:''} 
                             onChange={(e)=>setMyCurrentAnswer(e.target.value)}
                        />
                        <label htmlFor="option4" style={{fontWeight:'500',fontSize:'18px',marginLeft:'0.5rem'}}>{apiQuestionData?apiQuestionData[currentquestion].options[3]:''}</label><br/>
                        <button type='submit' className={timeup?'disablebsaveandnextbtn':'saveandnextbtn'} disabled={timeup?true:false}>Save & Next</button>
                        <span className={timeup?'disablebskipandnextbtn':'skipandnextbtn'} onClick={skipclickhandler}>Skip</span>
                </form>
                </div>        
              <div className='testMaincontainer2'>
                   <h4 className='mt-2'>Test</h4>
                   <div className='d-flex flex-wrap m-1'>
                   {apiQuestionData?apiQuestionData.map((item,index)=><span key={item.id} className={index==currentquestion?'currentquestionnumbercontainer':'altcurrentquestionnumbercontainer'}>{index+1}</span>):''}
                   </div>
                   
                   <Link className='mt-4' to={`/student/${examname}/${examid}/starttest/submit/${answersArray.length!=0?answersArray:0}/${totalAttempts.length!=0?totalAttempts:0}/${Time}/${minutes}`}><button className='languagebtn'>Submit</button></Link>
                   
              </div>
           </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default Test