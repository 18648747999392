import {createContext, useState} from "react"

export const AdminContext=createContext()

export const AdminProvider=({children})=>{
    const [adminLoginDetailsContext,setAdminLoginDetailsContext]=useState()
    const [studentdataforadmin,setStudentdataforadmin]=useState()
    const [teachersdataadmin,setTeachersdataadmin]=useState()
    return(
        <AdminContext.Provider
           value={{
            setAdminLoginDetailsContext,
            adminLoginDetailsContext,
            setStudentdataforadmin,
            setTeachersdataadmin,
            studentdataforadmin,
            teachersdataadmin
           }}
        >
           {children}
        </AdminContext.Provider>
    )
}