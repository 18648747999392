import React,
       {useEffect,useState}  from 'react'
import Navigation from '../../../components/Navbar'
import axios from 'axios'
import "./showexam.css"

const ShowGivenExam = () => {
    const [questionlist,setQuestionList]=useState()
    const studentLoginDetails=JSON.parse(localStorage.getItem('studentLoginDetails'))
    const studentLoginData=JSON.parse(localStorage.getItem('loginData'))
    const config={
        headers:{
              Authorization:`Bearer ${studentLoginData.data.token}`,
              "Access-Control-Allow-Origin": "*",
            }
          }
    async function GetTheGivenExam(){
        try{
            const data=await axios.get('https://onlinetestapi.leetwolf.in/api/v1/student/getgivenexam',config)
            if(data){
                setQuestionList(data)
                console.log(data)
            }
        }catch(err){
            console.log(err)
        }
    }
    useEffect(()=>{
      GetTheGivenExam()
    },[])
  return (
    <div>
       <Navigation required={true} 
      studentName={studentLoginDetails.data.student.firstName}
       />
       <div className="showgivenexammaincontainer"> 
           {
            questionlist?
            questionlist.data.data.map(item=>
            <div className="showexamquestioncontainer">
            Question:<span style={{fontWeight:"bold"}}>&nbsp;&nbsp;{item.question.question}</span>
                 {
                    item.question.options.map((op,index)=>
                    <p>{index+1}{" "}{op}</p>)
                 }
                 <h3 className={item.result=="right"?'showexamright':'showexamwrong'} >Your answer: {item.answer}</h3>
                 <h2>Correct Answer: {item.question.answer}</h2>
            </div>)
            :''
           }
       </div>
    </div>
  )
}

export default ShowGivenExam